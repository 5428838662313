/* eslint-disable max-len */
/* eslint-disable camelcase */
/**
 * @name MarkerClustererPlus for Google Maps V3
 * @author Gary Little
 * @fileoverview
 * The library creates and manages per-zoom-level clusters for large amounts of markers.
 * <p>
 * This is an enhanced V3 implementation of the V2 MarkerClusterer by Xiaoxi Wu. It is
 * based on the V3 MarkerClusterer port by Luke Mahe. MarkerClustererPlus was created
 * by Gary Little.
 * <p>
 * v2.0 release: MarkerClustererPlus v2.0 is backward compatible with MarkerClusterer v1.0. It
 *  adds support for the <code>ignoreHidden</code>, <code>title</code>, <code>batchSizeIE</code>,
 *  and <code>calculator</code> properties as well as support for four more events. It also allows
 *  greater control over the styling of the text that appears on the cluster marker. The
 *  documentation has been significantly improved and the overall code has been simplified and
 *  polished. Very large numbers of markers can now be managed without causing Javascript timeout
 *  errors on Internet Explorer. Note that the name of the <code>clusterclick</code> event has been
 *  deprecated. The new name is <code>click</code>, so please change your application code now.
 */

/**
 * Licensed under the Apache License, Version 2.0 (the 'License');
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an 'AS IS' BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/**
 * @name ClusterIconStyle
 * @class This class represents the object for values in the <code>styles</code> array passed
 *  to the {@link MarkerClusterer} constructor. The element in this array that is used to
 *  style the cluster icon is determined by calling the <code>calculator</code> function.
 *
 * @property {string} url The URL of the cluster icon image file. Required.
 * @property {number} height The display height (in pixels) of the cluster icon. Required.
 * @property {number} width The display width (in pixels) of the cluster icon. Required.
 * @property {Array} [anchorText] The position (in pixels) from the center of the cluster icon to
 *  where the text label is to be centered and drawn. The format is <code>[yoffset, xoffset]</code>
 *  where <code>yoffset</code> increases as you go down from center and <code>xoffset</code>
 *  increases to the right of center. The default is <code>[0, 0]</code>.
 * @property {Array} [anchorIcon] The anchor position (in pixels) of the cluster icon. This is the
 *  spot on the cluster icon that is to be aligned with the cluster position. The format is
 *  <code>[yoffset, xoffset]</code> where <code>yoffset</code> increases as you go down and
 *  <code>xoffset</code> increases to the right of the top-left corner of the icon. The default
 *  anchor position is the center of the cluster icon.
 * @property {string} [textColor='black'] The color of the label text shown on the
 *  cluster icon.
 * @property {number} [textSize=11] The size (in pixels) of the label text shown on the
 *  cluster icon.
 * @property {string} [textDecoration='none'] The value of the CSS <code>text-decoration</code>
 *  property for the label text shown on the cluster icon.
 * @property {string} [fontWeight='bold'] The value of the CSS <code>font-weight</code>
 *  property for the label text shown on the cluster icon.
 * @property {string} [fontStyle='normal'] The value of the CSS <code>font-style</code>
 *  property for the label text shown on the cluster icon.
 * @property {string} [fontFamily='Arial,sans-serif'] The value of the CSS <code>font-family</code>
 *  property for the label text shown on the cluster icon.
 * @property {string} [backgroundPosition='0 0'] The position of the cluster icon image
 *  within the image defined by <code>url</code>. The format is <code>'xpos ypos'</code>
 *  (the same format as for the CSS <code>background-position</code> property). You must set
 *  this property appropriately when the image defined by <code>url</code> represents a sprite
 *  containing multiple images. Note that the position <i>must</i> be specified in px units.
 */
/**
 * @name ClusterIconInfo
 * @class This class is an object containing general information about a cluster icon. This is
 *  the object that a <code>calculator</code> function returns.
 *
 * @property {string} text The text of the label to be shown on the cluster icon.
 * @property {number} index The index plus 1 of the element in the <code>styles</code>
 *  array to be used to style the cluster icon.
 * @property {string} title The tooltip to display when the mouse moves over the cluster icon.
 *  If this value is <code>undefined</code> or <code>''</code>, <code>title</code> is set to the
 *  value of the <code>title</code> property passed to the MarkerClusterer.
 */
/**
 * A cluster icon.
 *
 * @constructor
 * @extends google.maps.OverlayView
 * @param {Cluster} cluster The cluster with which the icon is to be associated.
 * @param {Array} [styles] An array of {@link ClusterIconStyle} defining the cluster icons
 *  to use for various cluster sizes.
 * @private
 */
class ClusterIcon {
  constructor (cluster, styles) {
    cluster.getMarkerClusterer().extend(ClusterIcon, google.maps.OverlayView);

    this.cluster_ = cluster;
    this.className_ = cluster.getMarkerClusterer().getClusterClass();
    this.styles_ = styles;
    this.center_ = null;
    this.div_ = null;
    this.sums_ = null;
    this.visible_ = false;

    this.setMap(cluster.getMap()); // Note: this causes onAdd to be called
  }

  /**
   * Adds the icon to the DOM.
   */
  onAdd () {
    var cClusterIcon = this;
    var cMouseDownInCluster;
    var cDraggingMapByCluster;
    var gmVersion = google.maps.version.split('.');

    gmVersion = parseInt(gmVersion[0] * 100, 10) + parseInt(gmVersion[1], 10);

    this.div_ = document.createElement('div');
    this.div_.className = this.className_;
    if (this.visible_) {
      this.show();
    }

    this.getPanes().overlayMouseTarget.appendChild(this.div_);

    // Fix for Issue 157
    this.boundsChangedListener_ = google.maps.event.addListener(this.getMap(), 'bounds_changed', function () {
      cDraggingMapByCluster = cMouseDownInCluster;
    });

    google.maps.event.addDomListener(this.div_, 'mousedown', function () {
      cMouseDownInCluster = true;
      cDraggingMapByCluster = false;
    });

    // March 1, 2018: Fix for this 3.32 exp bug, https://issuetracker.google.com/issues/73571522
    // But it doesn't work with earlier releases so do a version check.
    if (gmVersion >= 332) { // Ugly version-dependent code
      google.maps.event.addDomListener(this.div_, 'touchstart', function (e) {
        e.stopPropagation();
      });
    }

    google.maps.event.addDomListener(this.div_, 'click', function (e) {
      cMouseDownInCluster = false;
      if (!cDraggingMapByCluster) {
        var theBounds;
        var mz;
        var mc = cClusterIcon.cluster_.getMarkerClusterer();
        /**
         * This event is fired when a cluster marker is clicked.
         * @name MarkerClusterer#click
         * @param {Cluster} c The cluster that was clicked.
         * @event
         */
        google.maps.event.trigger(mc, 'click', cClusterIcon.cluster_);
        google.maps.event.trigger(mc, 'clusterclick', cClusterIcon.cluster_); // deprecated name

        // The default click handler follows. Disable it by setting
        // the zoomOnClick property to false.
        if (mc.getZoomOnClick()) {
          // Zoom into the cluster.
          mz = mc.getMaxZoom();
          theBounds = cClusterIcon.cluster_.getBounds();
          mc.getMap().fitBounds(theBounds);
          // There is a fix for Issue 170 here:
          setTimeout(function () {
            mc.getMap().fitBounds(theBounds);
            // Don't zoom beyond the max zoom level
            if (mz !== null && (mc.getMap().getZoom() > mz)) {
              mc.getMap().setZoom(mz + 1);
            }
          }, 100);
        }

        // Prevent event propagation to the map:
        e.cancelBubble = true;
        if (e.stopPropagation) {
          e.stopPropagation();
        }
      }
    });

    google.maps.event.addDomListener(this.div_, 'mouseover', function () {
      var mc = cClusterIcon.cluster_.getMarkerClusterer();
      /**
       * This event is fired when the mouse moves over a cluster marker.
       * @name MarkerClusterer#mouseover
       * @param {Cluster} c The cluster that the mouse moved over.
       * @event
       */
      google.maps.event.trigger(mc, 'mouseover', cClusterIcon.cluster_);
    });

    google.maps.event.addDomListener(this.div_, 'mouseout', function () {
      var mc = cClusterIcon.cluster_.getMarkerClusterer();
      /**
       * This event is fired when the mouse moves out of a cluster marker.
       * @name MarkerClusterer#mouseout
       * @param {Cluster} c The cluster that the mouse moved out of.
       * @event
       */
      google.maps.event.trigger(mc, 'mouseout', cClusterIcon.cluster_);
    });
  }

  /**
   * Removes the icon from the DOM.
   */
  onRemove () {
    if (this.div_ && this.div_.parentNode) {
      this.hide();
      google.maps.event.removeListener(this.boundsChangedListener_);
      google.maps.event.clearInstanceListeners(this.div_);
      this.div_.parentNode.removeChild(this.div_);
      this.div_ = null;
    }
  }

  /**
   * Draws the icon.
   */
  draw () {
    if (this.visible_) {
      var pos = this.getPosFromLatLng_(this.center_);
      this.div_.style.top = pos.y + 'px';
      this.div_.style.left = pos.x + 'px';
      this.div_.style.zIndex = google.maps.Marker.MAX_ZINDEX + 1; // Put above all unclustered markers
    }
  }

  /**
   * Hides the icon.
   */
  hide () {
    if (this.div_) {
      this.div_.style.display = 'none';
    }
    this.visible_ = false;
  }

  /**
   * Positions and shows the icon.
   */
  show () {
    if (this.div_) {
      var mc = this.cluster_.markerClusterer_;
      var ariaLabel = mc.ariaLabelFn(this.sums_.text);
      var img = '';
      // NOTE: values must be specified in px units
      var bp = this.backgroundPosition_.split(' ');
      var spriteH = parseInt(bp[0].replace(/^\s+|\s+$/g, ''), 10);
      var spriteV = parseInt(bp[1].replace(/^\s+|\s+$/g, ''), 10);
      var pos = this.getPosFromLatLng_(this.center_);
      this.div_.style.cssText = this.createCss(pos);
      img = '<img aria-hidden="true" src="' + this.url_ + '" style="position: absolute; top: ' + spriteV + 'px; left: ' + spriteH + 'px; ';
      if (this.cluster_.getMarkerClusterer().enableRetinaIcons_) {
        img += 'width: ' + this.width_ + 'px; height: ' + this.height_ + 'px;';
      } else {
        img += 'clip: rect(' + (-1 * spriteV) + 'px, ' + ((-1 * spriteH) + this.width_) + 'px, ' +
          ((-1 * spriteV) + this.height_) + 'px, ' + (-1 * spriteH) + 'px);';
      }
      img += '">';
      this.div_.innerHTML = img + '<div ' +
        'aria-label="' + ariaLabel + '" ' +
        'tabindex="0" ' +
        'style="' +
        'position: absolute;' +
        'top: ' + this.anchorText_[0] + 'px;' +
        'left: ' + this.anchorText_[1] + 'px;' +
        'color: ' + this.textColor_ + ';' +
        'font-size: ' + this.textSize_ + 'px;' +
        'font-family: ' + this.fontFamily_ + ';' +
        'font-weight: ' + this.fontWeight_ + ';' +
        'font-style: ' + this.fontStyle_ + ';' +
        'text-decoration: ' + this.textDecoration_ + ';' +
        'text-align: center;' +
        'width: ' + this.width_ + 'px;' +
        'line-height:' + this.height_ + 'px;' +
        '">' + '<span aria-hidden="true">' + this.sums_.text + '</span>' + '</div>';
      if (typeof this.sums_.title === 'undefined' || this.sums_.title === '') {
        this.div_.title = this.cluster_.getMarkerClusterer().getTitle();
      } else {
        this.div_.title = this.sums_.title;
      }
      this.div_.style.display = '';
    }
    this.visible_ = true;
  }

  /**
   * Sets the icon styles to the appropriate element in the styles array.
   *
   * @param {ClusterIconInfo} sums The icon label text and styles index.
   */
  useStyle (sums) {
    this.sums_ = sums;
    var index = Math.max(0, sums.index - 1);
    index = Math.min(this.styles_.length - 1, index);
    var style = this.styles_[index];
    this.url_ = style.url;
    this.height_ = style.height;
    this.width_ = style.width;
    this.anchorText_ = style.anchorText || [0, 0];
    this.anchorIcon_ = style.anchorIcon || [parseInt(this.height_ / 2, 10), parseInt(this.width_ / 2, 10)];
    this.textColor_ = style.textColor || 'black';
    this.textSize_ = style.textSize || 11;
    this.textDecoration_ = style.textDecoration || 'none';
    this.fontWeight_ = style.fontWeight || 'bold';
    this.fontStyle_ = style.fontStyle || 'normal';
    this.fontFamily_ = style.fontFamily || 'Arial,sans-serif';
    this.backgroundPosition_ = style.backgroundPosition || '0 0';
  }

  /**
   * Sets the position at which to center the icon.
   *
   * @param {google.maps.LatLng} center The latlng to set as the center.
   */
  setCenter (center) {
    this.center_ = center;
  }

  /**
   * Creates the cssText style parameter based on the position of the icon.
   *
   * @param {google.maps.Point} pos The position of the icon.
   * @return {string} The CSS style text.
   */
  createCss (pos) {
    var style = [];
    style.push('cursor: pointer;');
    style.push('position: absolute; top: ' + pos.y + 'px; left: ' + pos.x + 'px;');
    style.push('width: ' + this.width_ + 'px; height: ' + this.height_ + 'px;');
    style.push('-webkit-user-select: none;');
    style.push('-khtml-user-select: none;');
    style.push('-moz-user-select: none;');
    style.push('-o-user-select: none;');
    style.push('user-select: none;');
    return style.join('');
  }

  /**
   * Returns the position at which to place the DIV depending on the latlng.
   *
   * @param {google.maps.LatLng} latlng The position in latlng.
   * @return {google.maps.Point} The position in pixels.
   */
  getPosFromLatLng_ (latlng) {
    var pos = this.getProjection().fromLatLngToDivPixel(latlng);
    pos.x -= this.anchorIcon_[1];
    pos.y -= this.anchorIcon_[0];
    pos.x = parseInt(pos.x, 10);
    pos.y = parseInt(pos.y, 10);
    return pos;
  }
}

/**
 * Creates a single cluster that manages a group of proximate markers.
 *  Used internally, do not call this constructor directly.
 * @constructor
 * @param {MarkerClusterer} mc The <code>MarkerClusterer</code> object with which this
 *  cluster is associated.
 */
class Cluster {
  constructor (mc) {
    this.markerClusterer_ = mc;
    this.map_ = mc.getMap();
    this.gridSize_ = mc.getGridSize();
    this.minClusterSize_ = mc.getMinimumClusterSize();
    this.averageCenter_ = mc.getAverageCenter();
    this.markers_ = [];
    this.center_ = null;
    this.bounds_ = null;
    this.clusterIcon_ = new ClusterIcon(this, mc.getStyles());
  }

  /**
   * Returns the number of markers managed by the cluster. You can call this from
   * a <code>click</code>, <code>mouseover</code>, or <code>mouseout</code> event handler
   * for the <code>MarkerClusterer</code> object.
   *
   * @return {number} The number of markers in the cluster.
   */
  getSize () {
    return this.markers_.length;
  }

  /**
   * Returns the array of markers managed by the cluster. You can call this from
   * a <code>click</code>, <code>mouseover</code>, or <code>mouseout</code> event handler
   * for the <code>MarkerClusterer</code> object.
   *
   * @return {Array} The array of markers in the cluster.
   */
  getMarkers () {
    return this.markers_;
  }

  /**
   * Returns the center of the cluster. You can call this from
   * a <code>click</code>, <code>mouseover</code>, or <code>mouseout</code> event handler
   * for the <code>MarkerClusterer</code> object.
   *
   * @return {google.maps.LatLng} The center of the cluster.
   */
  getCenter () {
    return this.center_;
  }

  /**
   * Returns the map with which the cluster is associated.
   *
   * @return {google.maps.Map} The map.
   * @ignore
   */
  getMap () {
    return this.map_;
  }

  /**
   * Returns the <code>MarkerClusterer</code> object with which the cluster is associated.
   *
   * @return {MarkerClusterer} The associated marker clusterer.
   * @ignore
   */
  getMarkerClusterer () {
    return this.markerClusterer_;
  }

  /**
   * Returns the bounds of the cluster.
   *
   * @return {google.maps.LatLngBounds} the cluster bounds.
   * @ignore
   */
  getBounds () {
    var i;
    var bounds = new google.maps.LatLngBounds(this.center_, this.center_);
    var markers = this.getMarkers();
    for (i = 0; i < markers.length; i++) {
      bounds.extend(markers[i].getPosition());
    }
    return bounds;
  }

  /**
   * Removes the cluster from the map.
   *
   * @ignore
   */
  remove () {
    this.clusterIcon_.setMap(null);
    this.markers_ = [];
    delete this.markers_;
  }

  /**
   * Adds a marker to the cluster.
   *
   * @param {google.maps.Marker} marker The marker to be added.
   * @return {boolean} True if the marker was added.
   * @ignore
   */
  addMarker (marker) {
    var i;
    var mCount;
    var mz;

    if (this.isMarkerAlreadyAdded_(marker)) {
      return false;
    }

    if (!this.center_) {
      this.center_ = marker.getPosition();
      this.calculateBounds_();
    } else {
      if (this.averageCenter_) {
        var l = this.markers_.length + 1;
        var lat = (this.center_.lat() * (l - 1) + marker.getPosition().lat()) / l;
        var lng = (this.center_.lng() * (l - 1) + marker.getPosition().lng()) / l;
        this.center_ = new google.maps.LatLng(lat, lng);
        this.calculateBounds_();
      }
    }

    marker.isAdded = true;
    this.markers_.push(marker);

    mCount = this.markers_.length;
    mz = this.markerClusterer_.getMaxZoom();
    if (mz !== null && this.map_.getZoom() > mz) {
      // Zoomed in past max zoom, so show the marker.
      if (marker.getMap() !== this.map_) {
        marker.setMap(this.map_);
      }
    } else if (mCount < this.minClusterSize_) {
      // Min cluster size not reached so show the marker.
      if (marker.getMap() !== this.map_) {
        marker.setMap(this.map_);
      }
    } else if (mCount === this.minClusterSize_) {
      // Hide the markers that were showing.
      for (i = 0; i < mCount; i++) {
        this.markers_[i].setMap(null);
      }
    } else {
      marker.setMap(null);
    }

    this.updateIcon_();
    return true;
  }

  /**
   * Determines if a marker lies within the cluster's bounds.
   *
   * @param {google.maps.Marker} marker The marker to check.
   * @return {boolean} True if the marker lies in the bounds.
   * @ignore
   */
  isMarkerInClusterBounds (marker) {
    return this.bounds_.contains(marker.getPosition());
  }

  /**
   * Calculates the extended bounds of the cluster with the grid.
   */
  calculateBounds_ () {
    var bounds = new google.maps.LatLngBounds(this.center_, this.center_);
    this.bounds_ = this.markerClusterer_.getExtendedBounds(bounds);
  }

  /**
   * Updates the cluster icon.
   */
  updateIcon_ () {
    var mCount = this.markers_.length;
    var mz = this.markerClusterer_.getMaxZoom();

    if (mz !== null && this.map_.getZoom() > mz) {
      this.clusterIcon_.hide();
      return;
    }

    if (mCount < this.minClusterSize_) {
      // Min cluster size not yet reached.
      this.clusterIcon_.hide();
      return;
    }

    var numStyles = this.markerClusterer_.getStyles().length;
    var sums = this.markerClusterer_.getCalculator()(this.markers_, numStyles);
    this.clusterIcon_.setCenter(this.center_);
    this.clusterIcon_.useStyle(sums);
    this.clusterIcon_.show();
  }

  /**
   * Determines if a marker has already been added to the cluster.
   *
   * @param {google.maps.Marker} marker The marker to check.
   * @return {boolean} True if the marker has already been added.
   */
  isMarkerAlreadyAdded_ (marker) {
    var i;
    if (this.markers_.indexOf) {
      return this.markers_.indexOf(marker) !== -1;
    } else {
      for (i = 0; i < this.markers_.length; i++) {
        if (marker === this.markers_[i]) {
          return true;
        }
      }
    }
    return false;
  }
}

/**
 * @callback {Function} ariaLabelFnType
 * @param {string} text The text attribute of the cluster
 * @returns {string}
 */
/**
 * @name MarkerClustererOptions
 * @class This class represents the optional parameter passed to
 *  the {@link MarkerClusterer} constructor.
 * @property {number} [gridSize=60] The grid size of a cluster in pixels. The grid is a square.
 * @property {number} [maxZoom=null] The maximum zoom level at which clustering is enabled or
 *  <code>null</code> if clustering is to be enabled at all zoom levels.
 * @property {boolean} [zoomOnClick=true] Whether to zoom the map when a cluster marker is
 *  clicked. You may want to set this to <code>false</code> if you have installed a handler
 *  for the <code>click</code> event and it deals with zooming on its own.
 * @property {boolean} [averageCenter=false] Whether the position of a cluster marker should be
 *  the average position of all markers in the cluster. If set to <code>false</code>, the
 *  cluster marker is positioned at the location of the first marker added to the cluster.
 * @property {number} [minimumClusterSize=2] The minimum number of markers needed in a cluster
 *  before the markers are hidden and a cluster marker appears.
 * @property {boolean} [ignoreHidden=false] Whether to ignore hidden markers in clusters. You
 *  may want to set this to <code>true</code> to ensure that hidden markers are not included
 *  in the marker count that appears on a cluster marker (this count is the value of the
 *  <code>text</code> property of the result returned by the default <code>calculator</code>).
 *  If set to <code>true</code> and you change the visibility of a marker being clustered, be
 *  sure to also call <code>MarkerClusterer.repaint()</code>.
 * @property {string} [title=''] The tooltip to display when the mouse moves over a cluster
 *  marker. (Alternatively, you can use a custom <code>calculator</code> function to specify a
 *  different tooltip for each cluster marker.)
 * @property {function} [calculator=MarkerClusterer.CALCULATOR] The function used to determine
 *  the text to be displayed on a cluster marker and the index indicating which style to use
 *  for the cluster marker. The input parameters for the function are (1) the array of markers
 *  represented by a cluster marker and (2) the number of cluster icon styles. It returns a
 *  {@link ClusterIconInfo} object. The default <code>calculator</code> returns a
 *  <code>text</code> property which is the number of markers in the cluster and an
 *  <code>index</code> property which is one higher than the lowest integer such that
 *  <code>10^i</code> exceeds the number of markers in the cluster, or the size of the styles
 *  array, whichever is less. The <code>styles</code> array element used has an index of
 *  <code>index</code> minus 1. For example, the default <code>calculator</code> returns a
 *  <code>text</code> value of <code>'125'</code> and an <code>index</code> of <code>3</code>
 *  for a cluster icon representing 125 markers so the element used in the <code>styles</code>
 *  array is <code>2</code>. A <code>calculator</code> may also return a <code>title</code>
 *  property that contains the text of the tooltip to be used for the cluster marker. If
 *   <code>title</code> is not defined, the tooltip is set to the value of the <code>title</code>
 *   property for the MarkerClusterer.
 * @property {string} [clusterClass='cluster'] The name of the CSS class defining general styles
 *  for the cluster markers. Use this class to define CSS styles that are not set up by the code
 *  that processes the <code>styles</code> array.
 * @property {Array} [styles] An array of {@link ClusterIconStyle} elements defining the styles
 *  of the cluster markers to be used. The element to be used to style a given cluster marker
 *  is determined by the function defined by the <code>calculator</code> property.
 *  The default is an array of {@link ClusterIconStyle} elements whose properties are derived
 *  from the values for <code>imagePath</code>, <code>imageExtension</code>, and
 *  <code>imageSizes</code>.
 * @property {boolean} [enableRetinaIcons=false] Whether to allow the use of cluster icons that
 * have sizes that are some multiple (typically double) of their actual display size. Icons such
 * as these look better when viewed on high-resolution monitors such as Apple's Retina displays.
 * Note: if this property is <code>true</code>, sprites cannot be used as cluster icons.
 * @property {number} [batchSize=MarkerClusterer.BATCH_SIZE] Set this property to the
 *  number of markers to be processed in a single batch when using a browser other than
 *  Internet Explorer (for Internet Explorer, use the batchSizeIE property instead).
 * @property {number} [batchSizeIE=MarkerClusterer.BATCH_SIZE_IE] When Internet Explorer is
 *  being used, markers are processed in several batches with a small delay inserted between
 *  each batch in an attempt to avoid Javascript timeout errors. Set this property to the
 *  number of markers to be processed in a single batch; select as high a number as you can
 *  without causing a timeout error in the browser. This number might need to be as low as 100
 *  if 15,000 markers are being managed, for example.
 * @property {string} [imagePath=MarkerClusterer.IMAGE_PATH]
 *  The full URL of the root name of the group of image files to use for cluster icons.
 *  The complete file name is of the form <code>imagePath</code>n.<code>imageExtension</code>
 *  where n is the image file number (1, 2, etc.).
 * @property {string} [imageExtension=MarkerClusterer.IMAGE_EXTENSION]
 *  The extension name for the cluster icon image files (e.g., <code>'png'</code> or
 *  <code>'jpg'</code>).
 * @property {Array} [imageSizes=MarkerClusterer.IMAGE_SIZES]
 *  An array of numbers containing the widths of the group of
 *  <code>imagePath</code>n.<code>imageExtension</code> image files.
 *  (The images are assumed to be square.)
 * @property {ariaLabelFnType} [ariaLabelFn]
 *  A function to take the text attribute associated with the cluster and output a string to attach an
 *  ariaLabel to the cluter
 */
/**
 * Creates a MarkerClusterer object with the options specified in {@link MarkerClustererOptions}.
 * @constructor
 * @extends google.maps.OverlayView
 * @param {google.maps.Map} map The Google map to attach to.
 * @param {Array.<google.maps.Marker>} [opt_markers] The markers to be added to the cluster.
 * @param {MarkerClustererOptions} [opt_options] The optional parameters.
 */
class MarkerClusterer {
  constructor (map, opt_markers, opt_options) {
    // MarkerClusterer implements google.maps.OverlayView interface. We use the
    // extend function to extend MarkerClusterer with google.maps.OverlayView
    // because it might not always be available when the code is defined so we
    // look for it at the last possible moment. If it doesn't exist now then
    // there is no point going ahead :)
    this.extend(MarkerClusterer, google.maps.OverlayView);

    opt_markers = opt_markers || [];
    opt_options = opt_options || {};

    /**
   * The number of markers to process in one batch.
   *
   * @type {number}
   * @constant
   */
    this.BATCH_SIZE = 2000;

    /**
     * The number of markers to process in one batch (IE only).
     *
     * @type {number}
     * @constant
     */
    this.BATCH_SIZE_IE = 500;

    /**
     * The default root name for the marker cluster images.
     *
     * @type {string}
     * @constant
     */
    this.IMAGE_PATH = '../images/m';

    /**
     * The default extension name for the marker cluster images.
     *
     * @type {string}
     * @constant
     */
    this.IMAGE_EXTENSION = 'png';

    /**
     * The default array of sizes for the marker cluster images.
     *
     * @type {Array.<number>}
     * @constant
     */
    this.IMAGE_SIZES = [53, 56, 66, 78, 90];
    this.markers_ = [];
    this.clusters_ = [];
    this.listeners_ = [];
    this.activeMap_ = null;
    this.ready_ = false;
    this.ariaLabelFn = opt_options.ariaLabelFn || function () { return ''; };

    this.gridSize_ = opt_options.gridSize || 60;
    this.minClusterSize_ = opt_options.minimumClusterSize || 2;
    this.maxZoom_ = opt_options.maxZoom || null;
    this.styles_ = opt_options.styles || [];
    this.title_ = opt_options.title || '';
    this.zoomOnClick_ = true;
    if (opt_options.zoomOnClick !== undefined) {
      this.zoomOnClick_ = opt_options.zoomOnClick;
    }
    this.averageCenter_ = false;
    if (opt_options.averageCenter !== undefined) {
      this.averageCenter_ = opt_options.averageCenter;
    }
    this.ignoreHidden_ = false;
    if (opt_options.ignoreHidden !== undefined) {
      this.ignoreHidden_ = opt_options.ignoreHidden;
    }
    this.enableRetinaIcons_ = false;
    if (opt_options.enableRetinaIcons !== undefined) {
      this.enableRetinaIcons_ = opt_options.enableRetinaIcons;
    }
    this.imagePath_ = opt_options.imagePath || this.IMAGE_PATH;
    this.imageExtension_ = opt_options.imageExtension || this.IMAGE_EXTENSION;
    this.imageSizes_ = opt_options.imageSizes || this.IMAGE_SIZES;
    this.calculator_ = opt_options.calculator || this.CALCULATOR;
    this.batchSize_ = opt_options.batchSize || this.BATCH_SIZE;
    this.batchSizeIE_ = opt_options.batchSizeIE || this.BATCH_SIZE_IE;
    this.clusterClass_ = opt_options.clusterClass || 'cluster';

    if (navigator.userAgent.toLowerCase().indexOf('msie') !== -1) {
      // Try to avoid IE timeout when processing a huge number of markers:
      this.batchSize_ = this.batchSizeIE_;
    }

    this.setupStyles_();

    this.addMarkers(opt_markers, true);
    this.setMap(map); // Note: this causes onAdd to be called
  }

  /**
   * Implementation of the onAdd interface method.
   * @ignore
   */
  onAdd () {
    var cMarkerClusterer = this;

    this.activeMap_ = this.getMap();
    this.ready_ = true;

    this.repaint();

    this.prevZoom_ = this.getMap().getZoom();

    // Add the map event listeners
    this.listeners_ = [
      google.maps.event.addListener(this.getMap(), 'zoom_changed', function () {
        // Fix for bug #407
        // Determines map type and prevents illegal zoom levels
        var zoom = this.getMap().getZoom();
        var minZoom = this.getMap().minZoom || 0;
        var maxZoom = Math.min(this.getMap().maxZoom || 100,
          this.getMap().mapTypes[this.getMap().getMapTypeId()].maxZoom);
        zoom = Math.min(Math.max(zoom, minZoom), maxZoom);

        if (this.prevZoom_ !== zoom) {
          this.prevZoom_ = zoom;
          this.resetViewport_(false);
        }
      }.bind(this)),
      google.maps.event.addListener(this.getMap(), 'idle', function () {
        cMarkerClusterer.redraw_();
      }),
    ];
  }

  /**
   * Implementation of the onRemove interface method.
   * Removes map event listeners and all cluster icons from the DOM.
   * All managed markers are also put back on the map.
   * @ignore
   */
  onRemove () {
    var i;

    // Put all the managed markers back on the map:
    for (i = 0; i < this.markers_.length; i++) {
      if (this.markers_[i].getMap() !== this.activeMap_) {
        this.markers_[i].setMap(this.activeMap_);
      }
    }

    // Remove all clusters:
    for (i = 0; i < this.clusters_.length; i++) {
      this.clusters_[i].remove();
    }
    this.clusters_ = [];

    // Remove map event listeners:
    for (i = 0; i < this.listeners_.length; i++) {
      google.maps.event.removeListener(this.listeners_[i]);
    }
    this.listeners_ = [];

    this.activeMap_ = null;
    this.ready_ = false;
  }

  /**
   * Implementation of the draw interface method.
   * @ignore
   */
  draw () { };

  /**
   * Sets up the styles object.
   */
  setupStyles_ () {
    var i, size;
    if (this.styles_.length > 0) {
      return;
    }

    for (i = 0; i < this.imageSizes_.length; i++) {
      size = this.imageSizes_[i];
      this.styles_.push({
        url: this.imagePath_ + (i + 1) + '.' + this.imageExtension_,
        height: size,
        width: size,
      });
    }
  }

  /**
   *  Fits the map to the bounds of the markers managed by the clusterer.
   */
  fitMapToMarkers () {
    var i;
    var markers = this.getMarkers();
    var bounds = new google.maps.LatLngBounds();
    for (i = 0; i < markers.length; i++) {
      // March 3, 2018: Bug fix -- honor the ignoreHidden property
      if (markers[i].getVisible() || !this.getIgnoreHidden()) {
        bounds.extend(markers[i].getPosition());
      }
    }

    this.getMap().fitBounds(bounds);
  }

  /**
   * Returns the value of the <code>gridSize</code> property.
   *
   * @return {number} The grid size.
   */
  getGridSize () {
    return this.gridSize_;
  }

  /**
   * Sets the value of the <code>gridSize</code> property.
   *
   * @param {number} gridSize The grid size.
   */
  setGridSize (gridSize) {
    this.gridSize_ = gridSize;
  }

  /**
   * Returns the value of the <code>minimumClusterSize</code> property.
   *
   * @return {number} The minimum cluster size.
   */
  getMinimumClusterSize () {
    return this.minClusterSize_;
  }

  /**
   * Sets the value of the <code>minimumClusterSize</code> property.
   *
   * @param {number} minimumClusterSize The minimum cluster size.
   */
  setMinimumClusterSize (minimumClusterSize) {
    this.minClusterSize_ = minimumClusterSize;
  }

  /**
   *  Returns the value of the <code>maxZoom</code> property.
   *
   *  @return {number} The maximum zoom level.
   */
  getMaxZoom () {
    return this.maxZoom_;
  }

  /**
   *  Sets the value of the <code>maxZoom</code> property.
   *
   *  @param {number} maxZoom The maximum zoom level.
   */
  setMaxZoom (maxZoom) {
    this.maxZoom_ = maxZoom;
  }

  /**
   *  Returns the value of the <code>styles</code> property.
   *
   *  @return {Array} The array of styles defining the cluster markers to be used.
   */
  getStyles () {
    return this.styles_;
  }

  /**
   *  Sets the value of the <code>styles</code> property.
   *
   *  @param {Array.<ClusterIconStyle>} styles The array of styles to use.
   */
  setStyles (styles) {
    this.styles_ = styles;
  }

  /**
   * Returns the value of the <code>title</code> property.
   *
   * @return {string} The content of the title text.
   */
  getTitle () {
    return this.title_;
  }

  /**
   *  Sets the value of the <code>title</code> property.
   *
   *  @param {string} title The value of the title property.
   */
  setTitle (title) {
    this.title_ = title;
  }

  /**
   * Returns the value of the <code>zoomOnClick</code> property.
   *
   * @return {boolean} True if zoomOnClick property is set.
   */
  getZoomOnClick () {
    return this.zoomOnClick_;
  }

  /**
   *  Sets the value of the <code>zoomOnClick</code> property.
   *
   *  @param {boolean} zoomOnClick The value of the zoomOnClick property.
   */
  setZoomOnClick (zoomOnClick) {
    this.zoomOnClick_ = zoomOnClick;
  }

  /**
   * Returns the value of the <code>averageCenter</code> property.
   *
   * @return {boolean} True if averageCenter property is set.
   */
  getAverageCenter () {
    return this.averageCenter_;
  }

  /**
   *  Sets the value of the <code>averageCenter</code> property.
   *
   *  @param {boolean} averageCenter The value of the averageCenter property.
   */
  setAverageCenter (averageCenter) {
    this.averageCenter_ = averageCenter;
  }

  /**
   * Returns the value of the <code>ignoreHidden</code> property.
   *
   * @return {boolean} True if ignoreHidden property is set.
   */
  getIgnoreHidden () {
    return this.ignoreHidden_;
  }

  /**
   *  Sets the value of the <code>ignoreHidden</code> property.
   *
   *  @param {boolean} ignoreHidden The value of the ignoreHidden property.
   */
  setIgnoreHidden (ignoreHidden) {
    this.ignoreHidden_ = ignoreHidden;
  }

  /**
   * Returns the value of the <code>enableRetinaIcons</code> property.
   *
   * @return {boolean} True if enableRetinaIcons property is set.
   */
  getEnableRetinaIcons () {
    return this.enableRetinaIcons_;
  }

  /**
   *  Sets the value of the <code>enableRetinaIcons</code> property.
   *
   *  @param {boolean} enableRetinaIcons The value of the enableRetinaIcons property.
   */
  setEnableRetinaIcons (enableRetinaIcons) {
    this.enableRetinaIcons_ = enableRetinaIcons;
  }

  /**
   * Returns the value of the <code>imageExtension</code> property.
   *
   * @return {string} The value of the imageExtension property.
   */
  getImageExtension () {
    return this.imageExtension_;
  }

  /**
   *  Sets the value of the <code>imageExtension</code> property.
   *
   *  @param {string} imageExtension The value of the imageExtension property.
   */
  setImageExtension (imageExtension) {
    this.imageExtension_ = imageExtension;
  }

  /**
   * Returns the value of the <code>imagePath</code> property.
   *
   * @return {string} The value of the imagePath property.
   */
  getImagePath () {
    return this.imagePath_;
  }

  /**
   *  Sets the value of the <code>imagePath</code> property.
   *
   *  @param {string} imagePath The value of the imagePath property.
   */
  setImagePath (imagePath) {
    this.imagePath_ = imagePath;
  }

  /**
   * Returns the value of the <code>imageSizes</code> property.
   *
   * @return {Array} The value of the imageSizes property.
   */
  getImageSizes () {
    return this.imageSizes_;
  }

  /**
   *  Sets the value of the <code>imageSizes</code> property.
   *
   *  @param {Array} imageSizes The value of the imageSizes property.
   */
  setImageSizes (imageSizes) {
    this.imageSizes_ = imageSizes;
  }

  /**
   * Returns the value of the <code>calculator</code> property.
   *
   * @return {function} the value of the calculator property.
   */
  getCalculator () {
    return this.calculator_;
  }

  /**
   * Sets the value of the <code>calculator</code> property.
   *
   * @param {function(Array.<google.maps.Marker>, number)} calculator The value
   *  of the calculator property.
   */
  setCalculator (calculator) {
    this.calculator_ = calculator;
  }

  /**
   * Returns the value of the <code>batchSizeIE</code> property.
   *
   * @return {number} the value of the batchSizeIE property.
   */
  getBatchSizeIE () {
    return this.batchSizeIE_;
  }

  /**
   * Sets the value of the <code>batchSizeIE</code> property.
   *
   *  @param {number} batchSizeIE The value of the batchSizeIE property.
   */
  setBatchSizeIE (batchSizeIE) {
    this.batchSizeIE_ = batchSizeIE;
  }

  /**
   * Returns the value of the <code>clusterClass</code> property.
   *
   * @return {string} the value of the clusterClass property.
   */
  getClusterClass () {
    return this.clusterClass_;
  }

  /**
   * Sets the value of the <code>clusterClass</code> property.
   *
   *  @param {string} clusterClass The value of the clusterClass property.
   */
  setClusterClass (clusterClass) {
    this.clusterClass_ = clusterClass;
  }

  /**
   *  Returns the array of markers managed by the clusterer.
   *
   *  @return {Array} The array of markers managed by the clusterer.
   */
  getMarkers () {
    return this.markers_;
  }

  /**
   *  Returns the number of markers managed by the clusterer.
   *
   *  @return {number} The number of markers.
   */
  getTotalMarkers () {
    return this.markers_.length;
  }

  /**
   * Returns the current array of clusters formed by the clusterer.
   *
   * @return {Array} The array of clusters formed by the clusterer.
   */
  getClusters () {
    return this.clusters_;
  }

  /**
   * Returns the number of clusters formed by the clusterer.
   *
   * @return {number} The number of clusters formed by the clusterer.
   */
  getTotalClusters () {
    return this.clusters_.length;
  }

  /**
   * Adds a marker to the clusterer. The clusters are redrawn unless
   *  <code>opt_nodraw</code> is set to <code>true</code>.
   *
   * @param {google.maps.Marker} marker The marker to add.
   * @param {boolean} [opt_nodraw] Set to <code>true</code> to prevent redrawing.
   */
  addMarker (marker, opt_nodraw) {
    this.pushMarkerTo_(marker);
    if (!opt_nodraw) {
      this.redraw_();
    }
  }

  /**
   * Adds an array of markers to the clusterer. The clusters are redrawn unless
   *  <code>opt_nodraw</code> is set to <code>true</code>.
   *
   * @param {Array.<google.maps.Marker>} markers The markers to add.
   * @param {boolean} [opt_nodraw] Set to <code>true</code> to prevent redrawing.
   */
  addMarkers (markers, opt_nodraw) {
    var key;
    for (key in markers) {
      if (markers.hasOwnProperty(key)) {
        this.pushMarkerTo_(markers[key]);
      }
    }
    if (!opt_nodraw) {
      this.redraw_();
    }
  }

  /**
   * Pushes a marker to the clusterer.
   *
   * @param {google.maps.Marker} marker The marker to add.
   */
  pushMarkerTo_ (marker) {
    // If the marker is draggable add a listener so we can update the clusters on the dragend:
    if (marker.getDraggable()) {
      var cMarkerClusterer = this;
      google.maps.event.addListener(marker, 'dragend', function () {
        if (cMarkerClusterer.ready_) {
          this.isAdded = false;
          cMarkerClusterer.repaint();
        }
      });
    }
    marker.isAdded = false;
    this.markers_.push(marker);
  }

  /**
   * Removes a marker from the cluster.  The clusters are redrawn unless
   *  <code>opt_nodraw</code> is set to <code>true</code>. Returns <code>true</code> if the
   *  marker was removed from the clusterer.
   *
   * @param {google.maps.Marker} marker The marker to remove.
   * @param {boolean} [opt_nodraw] Set to <code>true</code> to prevent redrawing.
   * @return {boolean} True if the marker was removed from the clusterer.
   */
  removeMarker (marker, opt_nodraw) {
    var removed = this.removeMarker_(marker);

    if (!opt_nodraw && removed) {
      this.repaint();
    }

    return removed;
  }

  /**
   * Removes an array of markers from the cluster. The clusters are redrawn unless
   *  <code>opt_nodraw</code> is set to <code>true</code>. Returns <code>true</code> if markers
   *  were removed from the clusterer.
   *
   * @param {Array.<google.maps.Marker>} markers The markers to remove.
   * @param {boolean} [opt_nodraw] Set to <code>true</code> to prevent redrawing.
   * @return {boolean} True if markers were removed from the clusterer.
   */
  removeMarkers (markers, opt_nodraw) {
    var i, r;
    var removed = false;

    for (i = 0; i < markers.length; i++) {
      r = this.removeMarker_(markers[i]);
      removed = removed || r;
    }

    if (!opt_nodraw && removed) {
      this.repaint();
    }

    return removed;
  }

  /**
   * Removes a marker and returns true if removed, false if not.
   *
   * @param {google.maps.Marker} marker The marker to remove
   * @return {boolean} Whether the marker was removed or not
   */
  removeMarker_ (marker) {
    var i;
    var index = -1;
    if (this.markers_.indexOf) {
      index = this.markers_.indexOf(marker);
    } else {
      for (i = 0; i < this.markers_.length; i++) {
        if (marker === this.markers_[i]) {
          index = i;
          break;
        }
      }
    }

    if (index === -1) {
      // Marker is not in our list of markers, so do nothing:
      return false;
    }

    marker.setMap(null);
    this.markers_.splice(index, 1); // Remove the marker from the list of managed markers
    return true;
  }

  /**
   * Removes all clusters and markers from the map and also removes all markers
   *  managed by the clusterer.
   */
  clearMarkers () {
    this.resetViewport_(true);
    this.markers_ = [];
  }

  /**
   * Recalculates and redraws all the marker clusters from scratch.
   *  Call this after changing any properties.
   */
  repaint () {
    var oldClusters = this.clusters_.slice();
    this.clusters_ = [];
    this.resetViewport_(false);
    this.redraw_();

    // Remove the old clusters.
    // Do it in a timeout to prevent blinking effect.
    setTimeout(function () {
      var i;
      for (i = 0; i < oldClusters.length; i++) {
        oldClusters[i].remove();
      }
    }, 0);
  }

  /**
   * Returns the current bounds extended by the grid size.
   *
   * @param {google.maps.LatLngBounds} bounds The bounds to extend.
   * @return {google.maps.LatLngBounds} The extended bounds.
   * @ignore
   */
  getExtendedBounds (bounds) {
    var projection = this.getProjection();

    // Turn the bounds into latlng.
    var tr = new google.maps.LatLng(bounds.getNorthEast().lat(),
      bounds.getNorthEast().lng());
    var bl = new google.maps.LatLng(bounds.getSouthWest().lat(),
      bounds.getSouthWest().lng());

    // Convert the points to pixels and the extend out by the grid size.
    var trPix = projection.fromLatLngToDivPixel(tr);
    trPix.x += this.gridSize_;
    trPix.y -= this.gridSize_;

    var blPix = projection.fromLatLngToDivPixel(bl);
    blPix.x -= this.gridSize_;
    blPix.y += this.gridSize_;

    // Convert the pixel points back to LatLng
    var ne = projection.fromDivPixelToLatLng(trPix);
    var sw = projection.fromDivPixelToLatLng(blPix);

    // Extend the bounds to contain the new bounds.
    bounds.extend(ne);
    bounds.extend(sw);

    return bounds;
  }

  /**
   * Redraws all the clusters.
   */
  redraw_ () {
    this.createClusters_(0);
  }

  /**
   * Removes all clusters from the map. The markers are also removed from the map
   *  if <code>opt_hide</code> is set to <code>true</code>.
   *
   * @param {boolean} [opt_hide] Set to <code>true</code> to also remove the markers
   *  from the map.
   */
  resetViewport_ (opt_hide) {
    var i, marker;
    // Remove all the clusters
    for (i = 0; i < this.clusters_.length; i++) {
      this.clusters_[i].remove();
    }
    this.clusters_ = [];

    // Reset the markers to not be added and to be removed from the map.
    for (i = 0; i < this.markers_.length; i++) {
      marker = this.markers_[i];
      marker.isAdded = false;
      if (opt_hide) {
        marker.setMap(null);
      }
    }
  }

  resetViewport () {
    this.resetViewport_(false);
  }

  /**
   * Calculates the distance between two latlng locations in km.
   *
   * @param {google.maps.LatLng} p1 The first lat lng point.
   * @param {google.maps.LatLng} p2 The second lat lng point.
   * @return {number} The distance between the two points in km.
   * @see http://www.movable-type.co.uk/scripts/latlong.html
  */
  distanceBetweenPoints_ (p1, p2) {
    var R = 6371; // Radius of the Earth in km
    var dLat = (p2.lat() - p1.lat()) * Math.PI / 180;
    var dLon = (p2.lng() - p1.lng()) * Math.PI / 180;
    var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(p1.lat() * Math.PI / 180) * Math.cos(p2.lat() * Math.PI / 180) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;
    return d;
  }

  /**
   * Determines if a marker is contained in a bounds.
   *
   * @param {google.maps.Marker} marker The marker to check.
   * @param {google.maps.LatLngBounds} bounds The bounds to check against.
   * @return {boolean} True if the marker is in the bounds.
   */
  isMarkerInBounds_ (marker, bounds) {
    return bounds.contains(marker.getPosition());
  }

  /**
   * Adds a marker to a cluster, or creates a new cluster.
   *
   * @param {google.maps.Marker} marker The marker to add.
   */
  addToClosestCluster_ (marker) {
    var i, d, cluster, center;
    var distance = 40000; // Some large number
    var clusterToAddTo = null;
    for (i = 0; i < this.clusters_.length; i++) {
      cluster = this.clusters_[i];
      center = cluster.getCenter();
      if (center) {
        d = this.distanceBetweenPoints_(center, marker.getPosition());
        if (d < distance) {
          distance = d;
          clusterToAddTo = cluster;
        }
      }
    }

    if (clusterToAddTo && clusterToAddTo.isMarkerInClusterBounds(marker)) {
      clusterToAddTo.addMarker(marker);
    } else {
      cluster = new Cluster(this);
      cluster.addMarker(marker);
      this.clusters_.push(cluster);
    }
  }

  /**
   * Creates the clusters. This is done in batches to avoid timeout errors
   *  in some browsers when there is a huge number of markers.
   *
   * @param {number} iFirst The index of the first marker in the batch of
   *  markers to be added to clusters.
   */
  createClusters_ (iFirst) {
    var i, marker;
    var mapBounds;
    var cMarkerClusterer = this;
    if (!this.ready_) {
      return;
    }

    // Cancel previous batch processing if we're working on the first batch:
    if (iFirst === 0) {
      /**
       * This event is fired when the <code>MarkerClusterer</code> begins
       *  clustering markers.
       * @name MarkerClusterer#clusteringbegin
       * @param {MarkerClusterer} mc The MarkerClusterer whose markers are being clustered.
       * @event
       */
      google.maps.event.trigger(this, 'clusteringbegin', this);

      if (typeof this.timerRefStatic !== 'undefined') {
        clearTimeout(this.timerRefStatic);
        delete this.timerRefStatic;
      }
    }

    // Get our current map view bounds.
    // Create a new bounds object so we don't affect the map.
    //
    // See Comments 9 & 11 on Issue 3651 relating to this workaround for a Google Maps bug:
    if (this.getMap().getZoom() > 3) {
      mapBounds = new google.maps.LatLngBounds(this.getMap().getBounds().getSouthWest(),
        this.getMap().getBounds().getNorthEast());
    } else {
      mapBounds = new google.maps.LatLngBounds(new google.maps.LatLng(85.02070771743472, -178.48388434375), new google.maps.LatLng(-85.08136444384544, 178.00048865625));
    }
    var bounds = this.getExtendedBounds(mapBounds);

    var iLast = Math.min(iFirst + this.batchSize_, this.markers_.length);

    for (i = iFirst; i < iLast; i++) {
      marker = this.markers_[i];
      if (!marker.isAdded && this.isMarkerInBounds_(marker, bounds)) {
        if (!this.ignoreHidden_ || (this.ignoreHidden_ && marker.getVisible())) {
          this.addToClosestCluster_(marker);
        }
      }
    }

    if (iLast < this.markers_.length) {
      this.timerRefStatic = setTimeout(function () {
        cMarkerClusterer.createClusters_(iLast);
      }, 0);
    } else {
      delete this.timerRefStatic;

      /**
       * This event is fired when the <code>MarkerClusterer</code> stops
       *  clustering markers.
       * @name MarkerClusterer#clusteringend
       * @param {MarkerClusterer} mc The MarkerClusterer whose markers are being clustered.
       * @event
       */
      google.maps.event.trigger(this, 'clusteringend', this);
    }
  }

  /**
   * Extends an object's prototype by another's.
   *
   * @param {Object} obj1 The object to be extended.
   * @param {Object} obj2 The object to extend with.
   * @return {Object} The new extended object.
   * @ignore
   */
  extend (obj1, obj2) {
    return (function (object) {
      var property;
      for (property in object.prototype) {
        this.prototype[property] = object.prototype[property];
      }
      return this;
    }.apply(obj1, [obj2]));
  }

  /**
   * The default function for determining the label text and style
   * for a cluster icon.
   *
   * @param {Array.<google.maps.Marker>} markers The array of markers represented by the cluster.
   * @param {number} numStyles The number of marker styles available.
   * @return {ClusterIconInfo} The information resource for the cluster.
   * @constant
   * @ignore
   */
  CALCULATOR (markers, numStyles) {
    var index = 0;
    var title = '';
    var count = markers.length.toString();

    var dv = count;
    while (dv !== 0) {
      dv = parseInt(dv / 10, 10);
      index++;
    }

    index = Math.min(index, numStyles);
    return {
      text: count,
      index: index,
      title: title,
    };
  }
};

export default MarkerClusterer;
