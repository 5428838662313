import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  cardsContainer: {
    padding: 5
  },
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: "#ecb201",
      dark: "#ecb201"
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      light: "#0066ff",
      main: "#0044ff",
      // dark: will be calculated from palette.secondary.main,
      contrastText: "#ffcc00"
    }
    // error: will use the default color
  },
  header: {
    // backgroundColor: theme.palette.primary.dark,
    // color: theme.palette.primary.contrastText,
  },
  appBar: {
    backgroundColor: theme.palette.primary.dark
  },
  paddingRow: {
    marginTop: 10
    // marginBottom: 10,
  },
  relative: {
    position: "relative"
  },
  switch: {
    margin: 0,
    display: "flex",
    flexDirection: "row-reverse",
    justifyContent: "space-between",
    padding: 0
  }
}));

export default useStyles;
