import React from "react";
import ReactDOM from "react-dom";
import WrappedViewport from "./components/WrappedViewport";
import { AppContainer } from "react-hot-loader";
import { Provider } from "react-redux";
import axios from "axios";
import store from "./store";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  useHistory,
  useLocation
} from "react-router-dom";

// Detect users incorrectly hitting /locations/username instead of /username.
// It seems people think because the plugin is POSTing -> /location/username that they must
// view in browser at same url.  This is incorrect.
const path = window.location.pathname;

const pathQuery = path.match(/^\/locations\/(.*)$/);
if (pathQuery) {
  // Redirect /locations/username -> /username
  window.location.pathname = pathQuery[1];
}

const locationHash = (location.hash || "").substring(1);
if (locationHash) {
  window.location = "/" + locationHash;
}

const container = document.querySelector("#app-container");

function getParameterByName(name, url) {
  if (!url) url = window.location.href;
  name = name.replace(/[\[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

let accessToken = getParameterByName("accessToken");
if (accessToken) {
  localStorage.setItem("accessToken", accessToken);
} else {
  accessToken = localStorage.getItem("accessToken");
}

// axios request
const service = axios.create({
  baseURL: "https://api2.jojobus.com.hk", // base_url
  timeout: 5000 // request timeout
});
service.interceptors.request.use(
  config => {
    config.headers["api_key"] =
      "4j8nOXvpRkPwefwefCBbFF2GoiUrCfddVe7EygnGTWjhytL7dfKPqSNjMnHbWVjGt8yPOKe1iG69MRyRPVqIUXg2X0irW8s61ptprztUTlxj7YVOm8q7LEUBaIB4vzcFonXzoi";
    config.headers["token"] = accessToken;
    return config;
  },
  error => {
    console.log(error);
    Promise.reject(error);
  }
);
function getProfile(params) {
  console.log(params);
  return service({
    url: "/cms/GetProfile",
    method: "get",
    params
  });
}
//let response = await getProfile();
// var boolean = localStorage.getItem("boolean") || false;
getProfile().then(response => {
  // boolean = response.data.Code == 200 ? true : false;
  if (response.data.Code == 200) {
    localStorage.setItem("boolean", true);
  }
});

// end of axios

const render = () => {
  ReactDOM.render(
    <AppContainer>
      <Provider store={store}>
        <Router>
          <Switch>
            {/* <Route path="/:token" component={WrappedViewport} />
            <Route path="/" component={WrappedViewport} /> */}
            <Route path="/error">
              <ErrorPage />
            </Route>
            <PrivateRoute path="/">
              <Route path="/" component={WrappedViewport} />
            </PrivateRoute>
          </Switch>
        </Router>
      </Provider>
    </AppContainer>,
    container
  );
};

render();

if (module.hot) {
  module.hot.accept("./components/Viewport", () => {
    setImmediate(() => {
      ReactDOM.unmountComponentAtNode(container);
      render();
    });
  });
}

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
function PrivateRoute({ children, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        localStorage.getItem("boolean") ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/error",
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}

function ErrorPage() {
  // let history = useHistory();
  // let location = useLocation();

  // let { from } = location.state || { from: { pathname: "/" } };
  // let login = () => {
  //   fakeAuth.authenticate(() => {
  //     history.replace(from);
  //   });
  // };

  return (
    <div>
      {/* <p>You must log in to view the page at {from.pathname}</p>
      <button onClick={login}>Log in</button> */}
      <div> You are not authorized to access this website</div>
    </div>
  );
}
